:root {
  --primary-dark: #1a472a;    /* Dark green */
  --primary-light: #2d6a4f;   /* Lighter green */
  --accent: #74c69d;          /* Accent green */
  --text-light: #ffffff;
  --text-dark: #333333;
  --background-light: #f8f9fa;
  --card-hover: #e9ecef;
}

body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #121212 !important;
  margin: 0;
  padding: 0;
}

.header {
  background-color: rgba(18, 18, 18, 0.8); /* Slightly transparent dark */
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--text-primary);
  padding: 20px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.01em;
  color: var(--accent-orange);
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 30px; /* Consistent spacing between links */
}

.nav-links a {
  color: var(--text-secondary);
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  transition: all 0.2s ease;
  padding: 8px 0;
  position: relative;
}

/* Underline effect on hover */
.nav-links a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--accent-blue);
  transition: width 0.2s ease;
}

.nav-links a:hover {
  color: var(--text-primary);
}

.nav-links a:hover:after {
  width: 100%;
}

/* Active link style */
.nav-links a.active {
  color: var(--text-primary);
}

.nav-links a.active:after {
  width: 100%;
  background-color: var(--accent-blue);
}

.content {
  padding-top: 70px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #121212;
}

.home-content {
  top: 90px;
  padding-top: 65px;
  background-color: #121212;
}

.bills-content,
.members-content,
.about-container,
.laws-container {
  display: block;
  padding: 20px;
  margin-top: 70px;
  background-color: #121212;
}

.standard-bill, .law-item {
  border: none;
  background-color: white;
  color: var(--text-dark);
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.standard-bill:hover, .law-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background-color: var(--background-light);
}

.standard-member {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
}

.standard-member:hover {
  transform: translateY(-2px);
  border-color: var(--accent-blue);
  background: rgba(255, 255, 255, 0.05);
}

.member-info {
  flex: 1;
}

.member-info h2 {
  color: var(--text-primary);
  margin: 0 0 10px 0;
  font-size: 1.4em;
  font-weight: 500;
  letter-spacing: -0.01em;
}

.member-info p {
  color: var(--text-secondary);
  margin: 5px 0;
  font-size: 1rem;
}

.member-party {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.member-image {
  max-width: 100px;
  max-height: 130px;
  height: auto;
  border-radius: 4px;
  margin-left: 20px;
}

.members-content {
  max-width: 1000px;
  margin: 30px auto 0;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--primary-dark);
}

.end-message {
  text-align: center;
  color: var(--text-secondary);
  padding: 20px;
  font-style: italic;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .members-content {
    margin: 45px auto 70px;
    padding: 15px;
  }

  .standard-member {
    padding: 15px;
  }

  .member-info h2 {
    font-size: 1.2em;
  }

  .member-image {
    max-width: 80px;
    max-height: 100px;
  }
}

.bill-sponsor {
  left: 10px;
  margin: 10px;
}

#loading-icon {
  display: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #4a90e2;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.member-detail,
.bill-detail {
  max-width: 800px;
  margin: 100px auto 20px auto; /* Add top margin to push content below the header */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.member-detail h1,
.bill-detail h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.member-detail-divider {
  border: none;
  height: 1px;
  background-color: #ddd;
  margin: 10px 0 20px;
}

.member-detail-content {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.info-section {
  flex: 1;
  text-align: left;
  margin-top: 11px; /* Ensure text starts 11px below the divider */
  margin-left: 20px;
}

.image-section {
  flex: 0 0 auto;
  margin-top: 15px; /* Ensure image starts 15px below the divider */
  margin-right: 20px;
}

.member-image-detail {
  max-width: 200px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-section p {
  font-size: 1.1em;
  color: #555;
}

.info-section a {
  color: #1e90ff;
  text-decoration: none;
}

.info-section a:hover {
  text-decoration: underline;
}

.bill-detail-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
  justify-content: center;
}

.info-section {
  text-align: left;
}

.info-section h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #444;
}

.info-section p {
  font-size: 1.1em;
  color: #555;
}

/* EmailBox Component Styles */
.email-box {
  max-width: 400px;
  margin: 120px auto 20px; 
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 200px; /* Adjust this value as needed */
}

.email-input,
.submit-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.email-input {
  border: 1px solid #ccc;
}

.submit-button {
  background-color: var(--primary-dark);
  color: var(--text-light);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: var(--primary-light);
}

.email-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}
.member-detail {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  gap: 20px;
}

.member-detail img {
  max-width: 200px;
  height: auto;
}

.bill-summaries {
  margin-top: 20px;
}

.summary-item {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

.summary-item h3 {
  margin-top: 0;
  color: #333;
  font-size: 1.1em;
}

.summary-item p {
  margin-bottom: 10px;
}

.bill-links {
  display: flex;
  flex-direction: column;
  gap: 15px;  /* This creates space between the links */
  margin-top: 20px;
}

.bill-links a {
  display: inline-block;
  padding: 10px 15px;
  background-color: #f0f0f0;
  border-radius: 5px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease;
}

.bill-links a:hover {
  background-color: #e0e0e0;
}

/* Add/update these styles */
.bills-content {
  max-width: 1000px;
  margin: 30px auto 0;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--primary-dark);
}

.standard-bill {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
}

.standard-bill:hover {
  transform: translateY(-2px);
  border-color: var(--accent-blue);
  background: rgba(255, 255, 255, 0.05);
}

.standard-bill h2 {
  margin-top: 0;
  color: var(--text-primary);
  font-size: 1.4em;
  font-weight: 500;
  letter-spacing: -0.01em;
}

.standard-bill p {
  margin: 10px 0;
  color: var(--text-secondary);
  line-height: 1.5;
}

.app-container {
  min-height: 100vh;
  position: relative;
}

.main-layout {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 1800px;
  margin: 0 auto;
}

.ad-column {
  position: fixed;
  top: 90px; /* Adjust based on your header height */
  width: 160px;
}

.ad-column.left {
  left: max(20px, calc((100% - 1800px) / 2 + 20px));
}

.ad-column.right {
  right: max(20px, calc((100% - 1800px) / 2 + 20px));
}

.content {
  width: 100%;
  max-width: 1000px;
  margin: 70px auto 0;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Hide ads on smaller screens */
@media (max-width: 1400px) {
  .ad-column {
    display: none;
  }
}

.logo-mobile {
  display: none;
}

.logo-full {
  display: inline;
}

/* Mobile styles */
@media (max-width: 768px) {
  .logo-mobile {
    display: inline;
    font-size: 20px;
    font-weight: 500;
  }

  .logo-full {
    display: none;
  }

  .header {
    top: auto;  /* Remove top positioning */
    bottom: 0;  /* Position at bottom */
    padding: 10px 0;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Shadow on top instead of bottom */
  }

  .header-container {
    display: flex;
    justify-content: center; /* Center the navigation */
    padding: 0 15px;
  }

  /* Hide the logo on mobile */
  .logo {
    display: none;
  }

  .nav-links {
    display: flex;
    justify-content: space-evenly; /* Changed to space-evenly for equal spacing */
    width: 100%;
    margin: 0;
    padding: 0 10px;
  }

  .nav-links li {
    flex: 0 1 auto;
    margin: 0;
    text-align: center; /* Ensure text is centered */
  }

  .nav-links a {
    font-size: 18px;  /* Consistent 18px */
    padding: 12px 8px;
    display: inline-block;
    white-space: nowrap;
    color: var(--text-light);
    text-decoration: none;
  }

  .mobile-top-header {
    display: block;
  }

  .content {
    margin-top: 20px;    /* Reduced */
    margin-bottom: 70px;
  }

  .bills-content {
    margin-top: 0;       /* Reduced significantly */
    margin-bottom: 70px;
    padding: 0 15px;
  }

  .laws-container {
    margin-top: 50px;    /* Reduced top margin */
    margin-bottom: 70px;
  }

  .about-container {
    margin-top: 50px;    /* Reduced top margin */
    margin-bottom: 70px;
  }

  .member-detail-container {
    margin-top: 50px;    /* Reduced top margin */
    margin-bottom: 70px;
  }
}

/* Adjust main content padding for bottom navigation */
@media (max-width: 768px) {
  .content {
    margin-bottom: 80px; /* Add bottom margin to prevent content from being hidden behind the navigation */
    margin-top: 20px;    /* Reduce top margin since header is now at bottom */
  }

  .bills-content,
  .laws-container,
  .about-container,
  .member-detail-container {
    margin-top: 0;       /* Reduced */
    margin-bottom: 80px;
  }
}

/* Ensure minimum touch target size */
@media (max-width: 480px) {
  .nav-links a {
    min-width: 44px;
    min-height: 44px;
  }
}

/* Add new mobile top header styles */
.mobile-top-header {
  display: none;
  background-color: var(--primary-dark);
  color: var(--text-light);
  padding: 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  font-size: 20px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

/* Mobile top header styles */
.mobile-top-header {
  display: none; /* Hidden by default for desktop */
  background-color: var(--primary-dark);
  color: var(--text-light);
  padding: 12px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  text-align: center;
  box-sizing: border-box;
  height: 45px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .mobile-top-header {
    display: block; /* Show on mobile */
    height: 40px; /* Slightly smaller header */
  }

  /* Common mobile styles */
  .mobile-top-header {
    display: block;
    height: 40px;
  }

  /* Bills margin */
  .bills-content {
    margin-top: 20px;
    margin-bottom: 70px;
    padding: 0 15px;
  }

  /* Laws specific styles */
  .laws-list {
    margin-top: 40px !important;
    padding-top: 0 !important;
  }

  /* About specific styles */
  .about-content {
    margin-top: -40px !important;
    padding-top: 0 !important;
  }

  /* Override any other styles that might be affecting the margins */
  .laws-container,
  .about-container {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
}

/* Update the bills content margin */
.bills-content {
  max-width: 1000px;
  margin: 30px auto 0;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

/* Update laws container margin */
.laws-container {
  max-width: 1000px;
  margin: 30px auto 0;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

/* Update about container margin */
.about-container {
  max-width: 1000px;
  margin: 30px auto 0;
  padding: 0 30px;
}

@media (max-width: 768px) {
  /* ... other styles ... */

  /* More specific selector for bills */
  .bills-content.bills-content {
    margin-top: 40px !important;    /* Using !important to override other styles */
    margin-bottom: 70px;
    padding: 0 0px;
  }
}

/* Extra small devices */
@media (max-width: 360px) {
  .nav-links a {
    font-size: 18px;  /* Maintain 18px */
    padding: 12px 6px;
  }
}

/* Add/update these styles in your styles.css */

/* Home page mobile spacing */
@media (max-width: 768px) {
  .home-header {
    margin-top: 40px; /* Reduced from 60px */
    margin-bottom: 2rem;
  }
}

/* Members page spacing adjustments */
.members-content {
  margin-top: 40px; /* Desktop spacing unchanged */
}

/* Members page mobile spacing */
@media (max-width: 768px) {
  .members-content {
    margin-top: 0px; /* Reduced from 20px */
    margin-bottom: 70px; /* Keep space for bottom navigation */
  }
}

.logo-link {
  color: var(--accent-orange) !important;
  text-decoration: none;
}

.logo-full {
  color: var(--accent-orange);
}

