.laws-container {
    max-width: 1000px;
    margin: 30px auto 0;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--primary-dark);
  }
  
  .laws-list {
    list-style-type: none;
    padding: 0;
  }
  
  .law-item {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
  }
  
  .law-item:hover {
    transform: translateY(-2px);
    border-color: var(--accent-blue);
    background: rgba(255, 255, 255, 0.05);
  }
  
  .law-item h2 {
    margin-top: 0;
    color: var(--text-primary);
    font-size: 1.4em;
    font-weight: 500;
    letter-spacing: -0.01em;
  }
  
  .law-item p {
    margin: 10px 0;
    color: var(--text-secondary);
    line-height: 1.5;
  }
  
  .view-details-link {
    display: inline-block;
    margin-top: 10px;
    padding: 5px 10px;
    background-color: var(--accent-blue);
    color: var(--text-primary);
    text-decoration: none;
    border-radius: 3px;
    transition: opacity 0.2s ease;
  }
  
  .view-details-link:hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    .laws-container {
      
      padding: 0px 15px !important;
    }
  }