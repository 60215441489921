.about-container {
  max-width: 1000px;
  text-align: center;
  margin: 40px auto 0;
  padding: 0 30px;
}

.about-header {
  text-align: center;
  margin-bottom: 60px;
  color: var(--text-primary);
}

.about-header h1 {
  font-size: 2.8em;
  margin-bottom: 15px;
  font-weight: 600;
  color: var(--text-primary);
}

.subtitle {
  font-size: 1.4em;
  color: var(--text-secondary);
  margin: 0;
  font-weight: 300;
}

.about-content {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 40px;
  border-radius: 8px;
  backdrop-filter: blur(10px);
}

.about-section {
  margin-bottom: 40px;
}

.about-section h2 {
  color: var(--accent-blue);
  font-size: 1.8em;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
}

.about-section p {
  font-size: 1.1em;
  line-height: 1.6;
  color: var(--text-secondary);
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 30px;
  margin: 40px 0;
}

.feature-card {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 25px;
  border-radius: 8px;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
}

.feature-card:hover {
  transform: translateY(-5px);
  border-color: var(--accent-blue);
  background: rgba(255, 255, 255, 0.05);
}

.feature-card h3 {
  color: var(--text-primary);
  font-size: 1.3em;
  margin-bottom: 15px;
}

.feature-card p {
  color: var(--text-secondary);
  line-height: 1.5;
  margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-container {
    margin: 10px 10px !important;
    padding: 0px 0px !important;
  }

  .about-header {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .about-content {
    padding: 30px 30px;
    border-radius: 8px;
    margin-top: 50px !important;
  }

  .about-header h1 {
    font-size: 2.2em;
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 1.2em;
    margin-bottom: 0;
  }

  .features-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .about-section:first-child {
    margin-top: 40px;
  }
} 