.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  z-index: 9999;
}

.loading-spinner::before,
.loading-spinner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 4px;
  height: 100%;
  border-radius: 2px;
  transform-origin: center;
}

.loading-spinner::before {
  background-color: var(--accent-blue);
  animation: strand1 1.5s ease-in-out infinite;
}

.loading-spinner::after {
  background-color: var(--accent-orange);
  animation: strand2 1.5s ease-in-out infinite;
}

@keyframes strand1 {
  0% {
    transform: translateX(-10px) rotateZ(0deg) scaleY(1);
  }
  25% {
    transform: translateX(0px) rotateZ(180deg) scaleY(0.5);
  }
  50% {
    transform: translateX(10px) rotateZ(360deg) scaleY(1);
  }
  75% {
    transform: translateX(0px) rotateZ(180deg) scaleY(0.5);
  }
  100% {
    transform: translateX(-10px) rotateZ(0deg) scaleY(1);
  }
}

@keyframes strand2 {
  0% {
    transform: translateX(10px) rotateZ(0deg) scaleY(1);
  }
  25% {
    transform: translateX(0px) rotateZ(180deg) scaleY(0.5);
  }
  50% {
    transform: translateX(-10px) rotateZ(360deg) scaleY(1);
  }
  75% {
    transform: translateX(0px) rotateZ(180deg) scaleY(0.5);
  }
  100% {
    transform: translateX(10px) rotateZ(0deg) scaleY(1);
  }
} 