.cosponsors-section {
  margin-top: 20px;
}

.toggle-cosponsors-btn {
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-cosponsors-btn:hover {
  background-color: var(--hover-color);
  border-color: var(--accent-blue);
}

.toggle-summaries-btn {
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  margin-top: 10px;
  margin-bottom: 20px;
}

.toggle-summaries-btn:hover {
  background-color: var(--hover-color);
  border-color: var(--accent-blue);
}

.toggle-btn {
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-btn:hover {
  background-color: var(--hover-color);
  border-color: var(--accent-blue);
}

.additional-info {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background: transparent;
}

.additional-info h3 {
  color: var(--text-primary);
}

.additional-info ul {
  list-style-type: none;
  padding-left: 0;
  color: var(--text-secondary);
}

.summary-item {
  margin-bottom: 20px;
  background: transparent;
}

.summary-item h3 {
  color: var(--text-primary);
  margin-bottom: 10px;
}

.summary-item p {
  color: var(--text-secondary);
}

.bill-detail {
  max-width: 1000px;
  margin: 100px auto 0;
  padding: 30px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  backdrop-filter: blur(10px);
  color: var(--text-primary);
}

/* Mobile styles */
@media (max-width: 768px) {
  .bill-detail {
    margin: 45px auto 70px;
    padding: 15px;
    border-radius: 0;
  }
}

.bill-title {
  color: var(--text-primary);
  font-size: 1.8em;
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.bill-info {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
}

.bill-info-item {
  margin-bottom: 10px;
  padding-top: 100px;
}

.bill-info-item .label {
  font-weight: bold;
  margin-right: 10px;
}

.bill-section {
  margin-bottom: 30px;
}

.bill-section h2 {
  color: var(--accent-blue);
  font-size: 1.4em;
  margin-bottom: 15px;
}

.bill-section h3 {
  color: var(--text-primary);
  font-size: 18px;
}

.bill-section p {
  color: var(--text-secondary);
}

.sponsor-list,
.cosponsor-list {
  list-style-type: none;
  padding-left: 0;
}

.sponsor-list li,
.cosponsor-list li {
  margin-bottom: 5px;
  color: var(--text-secondary);
}

a {
  color: var(--accent-blue);
  text-decoration: none;
  transition: opacity 0.2s ease;
}

a:hover {
  opacity: 0.8;
  text-decoration: none;
}

/* Full Bill Text section */
.bill-section:last-child {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 30px;
  padding-top: 30px;
}

/* If needed, add some bottom margin to the cosponsors section */
.bill-section:nth-last-child(2) {
  margin-bottom: 30px;
}

/* Update the bill summary container */
.bill-summaries {
  background: transparent;
  border: 0px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  color: var(--text-secondary);
  backdrop-filter: blur(10px);
}
