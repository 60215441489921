.member-detail-container {
  margin: 100px auto 0;
  max-width: 1400px;
  padding: 0 80px;
  width: 100%;
  box-sizing: border-box;
}

.member-detail-box {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  backdrop-filter: blur(10px);
  padding: 40px 60px;
  overflow: hidden;
  color: var(--text-primary);
}

.member-detail-content {
  display: flex;
  align-items: flex-start;
}

.member-detail-text {
  flex: 1;
  padding-right: 40px;
}

.member-detail-text h1,
.member-detail-text h2,
.member-detail-text h3 {
  color: var(--text-primary);
}

.member-detail-text p {
  color: var(--text-secondary);
}

.member-detail-image {
  flex-shrink: 0;
  width: 200px;
  margin-top: 20px;
}

.member-detail-image img {
  max-width: 100%;
  height: 240px;
  object-fit: cover;
  display: block;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.toggle-button, .load-more-button {
  background-color: var(--background-light);
  color: var(--text-primary);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease;
}

.toggle-button:hover, .load-more-button:hover {
  background-color: var(--hover-color);
  border-color: var(--accent-blue);
}

.sponsored-bills-section {
  margin-top: 15px;
}

.sponsored-bills-list {
  padding-left: 0;
  list-style: none;
  margin: 0;
}

.sponsored-bill-item {
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.sponsored-bill-item:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: var(--accent-blue);
  transform: translateY(-1px);
}

.bill-info {
  flex: 1;
  margin-right: 10px;
  line-height: 1.4;
  color: var(--text-secondary);
}

.passed-badge {
  background-color: var(--accent-green);
  color: var(--text-primary);
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8em;
  flex-shrink: 0;
  align-self: flex-start;
}

/* Mobile styles */
@media (max-width: 768px) {
  .member-detail-container {
    margin: 45px auto 70px;
    padding: 0 15px;
  }

  .member-detail-box {
    padding: 20px;
    border-radius: 0;
  }

  .member-detail-content {
    flex-direction: column;
  }

  .member-detail-text {
    padding-right: 0;
  }

  .member-detail-image {
    width: 100%;
    margin: 20px 0;
    text-align: center;
  }

  .member-detail-image img {
    height: auto;
    max-width: 240px;
    margin: 0 auto;
  }

  .sponsored-bill-item {
    flex-direction: column;
    padding: 10px;
  }

  .bill-info {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .passed-badge {
    margin-top: 5px;
  }

  h1 {
    font-size: 1.5em;
    margin-top: 0;
  }

  h2 {
    font-size: 1.3em;
  }

  h3 {
    font-size: 1.2em;
  }
}
