@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

:root {
  /* Primary Colors - Adjusted to darker black */
  --primary-dark: #121212;     /* Main background - darker */
  --primary-light: #1A1A1A;    /* Secondary background */
  
  /* Accent Colors */
  --accent-orange: #FF9900;    /* Bloomberg's signature orange */
  --accent-blue: #00B4FF;      /* Data highlights */
  --accent-green: #00FF9D;     /* Positive trends */
  --accent-red: #FF3B3B;       /* Negative trends */
  
  /* Text Colors */
  --text-primary: #FFFFFF;     /* Main text */
  --text-secondary: #B3B3B3;   /* Secondary text */
  --text-muted: #666666;       /* Muted text */
  
  /* UI Elements - Match the darker theme */
  --background-light: #121212; /* Match primary-dark for consistency */
  --background-dark: #121212;  /* Match primary-dark for consistency */
  --border-color: #333333;     
  
  /* Interactive Elements */
  --hover-color: #2C2C2C;      /* Hover states */
  --active-color: #404040;     /* Active states */
  
  /* Chart Colors */
  --democrat-blue: #00B4FF;    /* Democratic party */
  --republican-red: #FF3B3B;   /* Republican party */
  --other-color: #666666;      /* Other parties */
  
  /* Font */
  --font-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

/* Target both html and body to ensure full coverage */
html, 
body {
  background-color: #121212 !important;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

#root {
  background-color: #121212;
  min-height: 100vh;
}

body {
  background-color: var(--primary-dark);
  color: var(--text-primary);
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

/* Card Styles */
.card {
  background-color: var(--background-light);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 1.5rem;
}

/* Button Styles */
.button {
  background-color: var(--accent-orange);
  color: var(--text-primary);
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.button:hover {
  opacity: 0.9;
}

/* Links */
a {
  color: var(--accent-blue);
  text-decoration: none;
  transition: opacity 0.2s ease;
}

a:hover {
  opacity: 0.8;
}

/* Headers */
h1, h2, h3, h4, h5, h6 {
  color: var(--text-primary);
  font-weight: 500;
}

/* Data Display */
.data-value {
  color: var(--accent-blue);
  font-family: monospace;
  font-size: 1.2rem;
}

.positive {
  color: var(--accent-green);
}

.negative {
  color: var(--accent-red);
}

/* Tables */
table {
  background-color: var(--background-light);
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid var(--border-color);
  padding: 0.75rem;
  text-align: left;
}

th {
  background-color: var(--primary-light);
  color: var(--text-secondary);
}

/* Input Fields */
input, select, textarea {
  background-color: var(--background-dark);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  padding: 0.5rem;
  border-radius: 4px;
}

input:focus, select:focus, textarea:focus {
  border-color: var(--accent-blue);
  outline: none;
}

/* Global typography */
h1 {
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: -0.02em;
}

h2 {
  font-size: 1.75rem;
  font-weight: 500;
  letter-spacing: -0.01em;
}

h3 {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: -0.01em;
}

p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}
