.committee-detail-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--primary-dark);
  color: var(--text-primary);
}

.committee-overview, 
.committee-info, 
.committee-members, 
.committee-history, 
.subcommittees, 
.committee-stats {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  background: transparent;
  backdrop-filter: blur(10px);
}

h1, h2 {
  color: var(--text-primary);
  font-weight: 500;
  letter-spacing: -0.5px;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.info-item h3 {
  color: var(--accent-blue);
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.info-item p {
  color: var(--text-primary);
  font-size: 1.2rem;
}

.membership-layout {
  display: flex;
  gap: 4rem;
  margin-top: 1rem;
}

.membership-column h3 {
  color: var(--accent-blue);
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.member-item {
  margin-bottom: 0.75rem;
  font-size: 1rem;
  color: var(--text-primary);
  line-height: 1.4;
}

.subcommittee-card {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.03);
  padding: 1.5rem;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.subcommittee-card:hover {
  transform: translateY(-2px);
  border-color: var(--accent-blue);
  background: rgba(255, 255, 255, 0.05);
}

.subcommittee-card h3 {
  color: var(--text-primary);
  margin: 0 0 0.5rem 0;
}

.system-code {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.stat-item h3 {
  color: var(--accent-blue);
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

.stat-item p {
  color: var(--text-primary);
  font-size: 1.2rem;
}

body {
  background: linear-gradient(to bottom right, var(--primary-dark), var(--primary-light));
  min-height: 100vh;
} 