.home-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--primary-dark);
  color: var(--text-primary);
}

.home-header {
  text-align: center;
  margin-bottom: 3rem;
}

.home-header h1 {
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.congress-dates {
  color: var(--text-secondary);
}

.congress-stats {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin: 2rem auto;
  background: transparent;
}

.congress-stats .chamber-stats {
  flex: 1;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 2rem;
  background: transparent !important;
  backdrop-filter: blur(10px);
  max-width: 500px;
  color: var(--text-primary);
}

.chamber-stats h2 {
  color: var(--text-primary) !important;
  margin-bottom: 2rem;
  font-weight: 500;
  letter-spacing: -0.01em;
}

.seats-visual {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto 2rem;
  background: transparent;
}

.seats-graph {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.total-seats {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.total-seats p {
  margin: 0;
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.total-seats strong {
  display: block;
  font-size: 2.5rem;
  color: var(--text-primary);
  line-height: 1;
}

.party-breakdown {
  background: transparent;
}

.party-count {
  text-align: center;
}

.party-count p {
  margin: 0;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--text-secondary);
}

.party-count.democrat p {
  color: #0015BC;
}

.party-count.republican p {
  color: #FF0000;
}

.party-count.other p {
  color: #666;
}

.party-count strong {
  display: block;
  font-size: 1.8rem;
  line-height: 1.2;
}

.party-count.democrat strong {
  color: var(--democrat-blue);
}

.party-count.republican strong {
  color: var(--republican-red);
}

.party-count.other strong {
  color: var(--text-secondary);
}

@media (max-width: 768px) {
  .congress-stats {
    flex-direction: column;
    gap: 2rem;
    margin: 1.5rem;
  }

  .party-count p {
    font-size: 0.8rem;
  }

  .party-count.democrat p::after {
    content: 'Dem';
  }
  
  .party-count.republican p::after {
    content: 'Rep';
  }
  
  .party-count.other p::after {
    content: 'Other';
  }
  
  .party-count p span {
    display: none;
  }
}

.chamber-card {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chamber-header {
  margin-bottom: 2rem;
  text-align: center;
}

.chamber-header h2 {
  font-size: 1.8rem;
  color: #1B1B3A;
  margin: 0;
}

.chamber-stats {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.chamber-stats::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(to right, var(--primary-dark), var(--primary-light));
  border-radius: 4px 4px 0 0;
}

.senate-stats::before {
  background: linear-gradient(to right, #1a472a, #2d6a4f);
}

.house-stats::before {
  background: linear-gradient(to right, #1a472a, #2d6a4f);
}

.chamber-stats:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.15);
}

.chamber-stats h2 {
  color: var(--primary-dark);
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  font-weight: 600;
}

.party-breakdown {
  background: var(--background-light);
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 1rem;
}

/* Add subtle background patterns */
.senate-stats {
  background-image: 
    radial-gradient(circle at 10% 20%, rgba(26, 71, 42, 0.03) 0%, transparent 20%),
    radial-gradient(circle at 90% 80%, rgba(45, 106, 79, 0.03) 0%, transparent 20%);
}

.house-stats {
  background-image: 
    radial-gradient(circle at 90% 20%, rgba(26, 71, 42, 0.03) 0%, transparent 20%),
    radial-gradient(circle at 10% 80%, rgba(45, 106, 79, 0.03) 0%, transparent 20%);
}

.stat-box {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 6px;
  text-align: center;
}

.stat-label {
  display: block;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.stat-number {
  display: block;
  font-size: 1.4rem;
  font-weight: bold;
  color: #1B1B3A;
}

.stat-number.democrat {
  color: #0015BC;
}

.stat-number.republican {
  color: #FF0000;
}

.party-distribution {
  display: flex;
  height: 50px;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 2rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.party-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  transition: width 0.3s ease;
  position: relative;
  min-width: 40px;
}

.party-count {
  font-size: 1.2rem;
}

.party-label {
  font-size: 0.8rem;
  opacity: 0.9;
}

.party-bar.democrat {
  background-color: #0015BC;
}

.party-bar.republican {
  background-color: #FF0000;
}

.party-bar.independent {
  background-color: #4B0082;
}

.chamber-leadership {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.leadership-title {
  font-size: 1.2rem;
  color: var(--text-dark);
  margin-bottom: 1rem;
}

.leader-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.leader {
  text-align: center;
}

.leader-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.leader-name {
  font-weight: 600;
  color: var(--primary-dark);
  margin: 0;
  font-size: 1rem;
}

.leader-title {
  font-size: 0.9rem;
  color: var(--text-dark);
  margin: 0.2rem 0 0;
}

.leader.democrat .leader-image {
  border-color: #0015BC;
}

.leader.republican .leader-image {
  border-color: #FF0000;
}

@media (min-width: 768px) {
  .congress-stats {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .home-container {
    padding: 0px;
  }
  
  .chamber-card {
    padding: 0px !important;
  }
  
  .home-header h1 {
    font-size: 2rem;
  }
}

.committees-section {
  margin-top: 3rem;
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background: transparent;
  backdrop-filter: blur(10px);
}

.committees-section h2 {
  text-align: center;
  color: var(--text-primary);
  margin-bottom: 2rem;
}

.committees-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 1024px) {
  .committees-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.committee-chamber h3 {
  color: var(--primary-dark);
  margin-bottom: 1.5rem;
  text-align: center;
}

.committee-card {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.03);
  padding: 1.5rem;
  border-radius: 6px;
  margin-bottom: 1rem;
  transition: all 0.2s ease;
}

.committee-card:hover {
  transform: translateY(-2px);
  border-color: var(--accent-blue);
  background: rgba(255, 255, 255, 0.05);
}

.committee-card h4 {
  color: var(--text-primary);
  margin: 0 0 0.5rem 0;
}

.subcommittee-list {
  margin-top: 1rem;
}

.subcommittee-list p {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.subcommittee-list ul {
  list-style: none;
  padding-left: 1rem;
}

.subcommittee-list li {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: var(--text-dark);
}

.committee-card::after {
  position: absolute;
  right: 1.5rem;
  opacity: 0;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.committee-card:hover::after {
  opacity: 0.5;
  transform: translateX(5px);
}

@media (max-width: 768px) {
  .congress-stats {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin: 1.5rem auto;
  }

  .chamber-stats {
    padding: 1.5rem;
  }

  .chamber-stats h2 {
    font-size: 1.5rem;
  }
}

/* Add subtle background pattern */
.chamber-stats::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%231a472a' fill-opacity='0.03' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.5;
}

.chamber-stats p, 
.chamber-stats h3, 
.chamber-stats span {
  color: var(--text-primary);
}

.stat-number {
  color: var(--text-primary);
}

.stat-number.democrat {
  color: var(--democrat-blue);
}

.stat-number.republican {
  color: var(--republican-red);
}

.stat-label {
  color: var(--text-secondary);
}

/* If needed, be even more specific */
.congress-stats .chamber-stats h2 {
  color: var(--text-primary) !important;
}